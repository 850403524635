import _turfIsolines from "turf-isolines";
import _turfConvex from "turf-convex";
import _turfWithin from "turf-within";
import _turfConcave from "turf-concave";
import _turfDifference from "turf-difference";
import _turfCollect from "turf-collect";
import _turfFlip from "turf-flip";
import _turfSimplify from "turf-simplify";
import _turfBezier from "turf-bezier";
import _turfTag from "turf-tag";
import _turfSample from "turf-sample";
import _turfEnvelope from "turf-envelope";
import _turfSquare from "turf-square";
import _turfMidpoint from "turf-midpoint";
import _turfBuffer from "turf-buffer";
import _turfCenter from "turf-center";
import _turfCentroid from "turf-centroid";
import _turfCombine from "turf-combine";
import _turfDistance from "turf-distance";
import _turfExplode from "turf-explode";
import _turfBbox from "turf-bbox";
import _turfTesselate from "turf-tesselate";
import _turfBboxPolygon from "turf-bbox-polygon";
import _turfInside from "turf-inside";
import _turfIntersect from "turf-intersect";
import _turfNearest from "turf-nearest";
import _turfPlanepoint from "turf-planepoint";
import _turfRandom from "turf-random";
import _turfTin from "turf-tin";
import _turfUnion from "turf-union";
import _turfBearing from "turf-bearing";
import _turfDestination from "turf-destination";
import _turfKinks from "turf-kinks";
import _turfPointOnSurface from "turf-point-on-surface";
import _turfArea from "turf-area";
import _turfAlong from "turf-along";
import _turfLineDistance from "turf-line-distance";
import _turfLineSlice from "turf-line-slice";
import _turfPointOnLine from "turf-point-on-line";
import _turfPointGrid from "turf-point-grid";
import _turfSquareGrid from "turf-square-grid";
import _turfTriangleGrid from "turf-triangle-grid";
import _turfHexGrid from "turf-hex-grid";
import _turfHelpers from "turf-helpers";
var exports = {};

/*eslint global-require: 0*/

/**
 * Turf is a modular geospatial analysis engine written in JavaScript. It performs geospatial
 * processing tasks with GeoJSON data and can be run on a server or in a browser.
 *
 * @module turf
 * @summary Geospatial analysis for JavaScript
 */
exports = {
  isolines: _turfIsolines,
  convex: _turfConvex,
  within: _turfWithin,
  concave: _turfConcave,
  difference: _turfDifference,
  collect: _turfCollect,
  flip: _turfFlip,
  simplify: _turfSimplify,
  bezier: _turfBezier,
  tag: _turfTag,
  sample: _turfSample,
  envelope: _turfEnvelope,
  square: _turfSquare,
  midpoint: _turfMidpoint,
  buffer: _turfBuffer,
  center: _turfCenter,
  centroid: _turfCentroid,
  combine: _turfCombine,
  distance: _turfDistance,
  explode: _turfExplode,
  bbox: _turfBbox,
  tesselate: _turfTesselate,
  bboxPolygon: _turfBboxPolygon,
  inside: _turfInside,
  intersect: _turfIntersect,
  nearest: _turfNearest,
  planepoint: _turfPlanepoint,
  random: _turfRandom,
  tin: _turfTin,
  union: _turfUnion,
  bearing: _turfBearing,
  destination: _turfDestination,
  kinks: _turfKinks,
  pointOnSurface: _turfPointOnSurface,
  area: _turfArea,
  along: _turfAlong,
  lineDistance: _turfLineDistance,
  lineSlice: _turfLineSlice,
  pointOnLine: _turfPointOnLine,
  pointGrid: _turfPointGrid,
  squareGrid: _turfSquareGrid,
  triangleGrid: _turfTriangleGrid,
  hexGrid: _turfHexGrid
};
var helpers = _turfHelpers;
exports.point = helpers.point;
exports.polygon = helpers.polygon;
exports.lineString = helpers.lineString;
exports.multiPoint = helpers.multiPoint;
exports.multiPolygon = helpers.multiPolygon;
exports.multiLineString = helpers.multiLineString;
exports.feature = helpers.feature;
exports.featureCollection = helpers.featureCollection;
exports.geometryCollection = helpers.geometryCollection;
export default exports;
export const isolines = exports.isolines,
      point = exports.point,
      polygon = exports.polygon,
      lineString = exports.lineString,
      multiPoint = exports.multiPoint,
      multiPolygon = exports.multiPolygon,
      multiLineString = exports.multiLineString,
      feature = exports.feature,
      featureCollection = exports.featureCollection,
      geometryCollection = exports.geometryCollection;